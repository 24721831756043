<template>
	<div class="product-nutrition">
		<div class="product-nutrition__title">Пищевая ценность блюда:</div>
		<div class="product-nutrition-rows">
			<div class="product-nutrition-row">
				<div class="product-nutrition-row__title">Белки</div>
				<span class="product-nutrition-row__dotts"></span>
				<div class="product-nutrition-row__value">{{ Math.round(item.belki * 0.01 * item.weight) }} гр.</div>
			</div>
			<div class="product-nutrition-row">
				<div class="product-nutrition-row__title">Жиры</div>
				<span class="product-nutrition-row__dotts"></span>
				<div class="product-nutrition-row__value">{{ Math.round(item.uglevodi * 0.01 * item.weight) }} гр.</div>
			</div>
			<div class="product-nutrition-row">
				<div class="product-nutrition-row__title">Углеводы</div>
				<span class="product-nutrition-row__dotts"></span>
				<div class="product-nutrition-row__value">{{ Math.round(item.jiri * 0.01 * item.weight) }} гр.</div>
			</div>
			<div class="product-nutrition-row">
				<div class="product-nutrition-row__title">Энерг. ценность</div>
				<span class="product-nutrition-row__dotts"></span>
				<div class="product-nutrition-row__value">{{ Math.round(item.kkal * 0.01 * item.weight) }} Ккл.</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['item'],
}
</script>

<style lang="scss">
.product-nutrition
{
	margin-bottom: 24px;
}

.product-nutrition__title
{
	font-weight: bold;
	font-size: 15px;
	line-height: 100%;
	color: $textColor;
	margin-bottom: 15px;
}

.product-nutrition-row
{
	display: flex;
	justify-content: space-between;

	&:not(:last-child)
	{
		margin-bottom: 12px;
	}

	.product-nutrition-row__dotts
	{
		background-image: linear-gradient(to right, $textColor 20%, rgba(61, 66, 72, 0) 0%);
		background-position: 0 11px;
		background-size: 5px 1px;
		background-repeat: repeat-x;
		flex: 1;
	}
}

.product-nutrition-row__title
{
	font-family: $mainFont;
	font-size: 14px;
	line-height: 100%;
	color: $greyText;
	padding-right: 3px;
}

.product-nutrition-row__value
{
	font-family: $mainFont;
	font-size: 14px;
	line-height: 100%;
	text-align: right;
	color: $greyText;
	padding-right: 3px;
	padding-left: 3px;
}

@media (max-width: 1200px)
{
	.product-nutrition
	{
		margin-bottom: 20px;
	}
}
</style>