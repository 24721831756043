import { render, staticRenderFns } from "./ProductNutrition.vue?vue&type=template&id=188ac82e"
import script from "./ProductNutrition.vue?vue&type=script&lang=js"
export * from "./ProductNutrition.vue?vue&type=script&lang=js"
import style0 from "./ProductNutrition.vue?vue&type=style&index=0&id=188ac82e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports