<!--Компонент карточки секции "не забудьте добавить" -->
<template>
	<div class="add-product-cart">
		<img
			src="@/assets/img/bread-1.webp"
			alt="product-image"
		>
		<p>Хлеб белый</p>
		<button class="add-product-cart__button btn">Выбрать</button>
	</div>
</template>

<script>
export default {
	data()
	{
		return {}
	}
}
</script>

<style
	lang="scss"
	scoped
>
.add-product-cart
{
	display: flex;
	align-items: center;
	flex-direction: column;
	max-width: 230px;
	padding: 16px;
	background-color: #ffffff;
	border: 1px solid #E5E5E7;
	border-radius: 16px;

	p
	{
		display: -webkit-box;
		max-width: 116px;
		max-height: 36px;
		margin: 8px 0 10px 0;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-family: $mainFontMediumNew;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		color: #3D4248;
	}
}

.add-product-cart__button
{padding: 8px 28px;}
</style>