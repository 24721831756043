<template>
	<transition-group
		name="lunch-cart-item"
		@enter="enter"
		@leave="leave"
		@afterEnter="afterEnter"
		@afterLeave="afterLeave"
	>
		<div
			class="lunch-cart-item lunch-cart-lunch"
			v-for="(item, index) in lunches"
			:key="JSON.stringify(item+index)"
		>
			<div
				class="lunch-cart-lunch__item"
				v-if="typeof item == 'object'"
			>
				<div
					class="lunch-cart-lunch__img"
					v-if="!!item.preview_picture"
				>
					<img
						:src="item ? item.preview_picture.thumb : ''"
						alt=""
					>
				</div>
				<div class="lunch-cart-lunch__desc">
					<div class="lunch-cart-lunch__title">{{ item.name }}</div>
					<div class="lunch-cart-lunch__type">
						{{ item.type.name }} • {{ item.weight }} гр / {{ item.kkal }} Ккл
					</div>
				</div>
				<div
					class="lunch-cart-lunch__remove-icon"
					@click="removeItem(item)"
					v-if="!isgift"
				>
					<svg
						width="24"
						height="24"
					>
						<use xlink:href="#remove-lunch-cart-item-icon"></use>
					</svg>
				</div>
				<div
					class="lunch-cart-lunch__gift-icon"
					v-if="isgift"
				>
					<svg
						fill="transparent"
						width="40"
						height="40"
					>
						<use xlink:href="#cart-row__gift"></use>
					</svg>
				</div>
			</div>
		</div>
	</transition-group>
</template>

<script>
export default {
	props: ['lunches', 'isgift', 'lunchIndex'],
	methods:
		{
			/**
			 * удалить итем или подарок из ланча
			 */
			removeItem(lunchItem)
			{
				if (!this.isgift)
					this.$emit('removeitem', this.lunchIndex, lunchItem);

				else
					this.$emit('removegift', lunchItem);
			},
			/**
			 ** transitions hooks
			 **/
			leave(element)
			{
				const height = '0px';

				element.style.height = getComputedStyle(element).height;

				setTimeout(() =>
				{
					element.style.height = height;
				});
			},

			/**
			 ** transitions hooks
			 **/
			enter(element)
			{
				const height = getComputedStyle(element).height;

				element.style.height = 0;

				setTimeout(() =>
				{
					element.style.height = height;
				});
			},

			/**
			 ** transitions hooks
			 **/
			afterEnter(element)
			{
				element.style.height = 'auto';
			},

			/**
			 ** transitions hooks
			 **/
			afterLeave(element)
			{
				element.style.height = 'auto';
			},
		}
}
</script>

<style lang="scss">
.lunch-cart-item
{
	margin-right: 10px;
	width: 100%;
}

.lunch-cart-item-enter, .lunch-cart-item-leave-to
{
	opacity: 0;
}

.lunch-cart-item-leave-active, .lunch-cart-item-enter-active
{
	transition-duration: 0.2s;
	transition-property: height, opacity, transform;
	overflow: hidden;
}

.lunch-cart-lunch:not(:empty)
{
	background-image: linear-gradient(to right, $textColor 20%, rgba(61, 66, 72, 0) 0%);
	background-position: top;
	background-size: 5px 1px;
	background-repeat: repeat-x;
	position: relative;

	&:not(:last-child)
	{padding-top: 8px; }

	&:first-child
	{background: none;}
}

.lunch-cart-lunch__item
{
	display: flex;
	position: relative;
	align-items: flex-start;
	padding-bottom: 8px;
	padding-top: 8px;
	position: relative;
}

.lunch-cart-lunch__desc
{
	margin-top: 8px;
	letter-spacing: .01px;
}

.lunch-cart-lunch__title
{
	font-family: $mainFontBoldNew;
	max-width: 180px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 100%;
	color: $textColor;
}

.lunch-cart-lunch__type
{
	font-family: $mainFontNew;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 15px;
	color: $greyText;
	padding-top: 4px;
}

.lunch-cart-lunch__img
{
	max-width: 55px;
	max-height: 50px;
	margin-right: 8px;
	margin-left: 3px;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.lunch-cart-lunch__remove-icon
{
	position: absolute;
	top: 4px;
	right: 0;
	cursor: pointer;
}

.lunch-cart-lunch__gift-icon
{
	line-height: 0;
	position: absolute;
	right: 0px;
	top: -5px;

	svg
	{
		width: 45px;
		height: 45px;
	}
}

@media (max-width: 767px)
{
	.lunch-cart-lunch__desc
	{
		margin-top: 9px;
	}
}
</style>
