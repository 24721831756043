<!-- Компонент заголовка секции --->
<template>
	<h3 class="section-title">
		<slot class="section-title__text"/>

		<div
			class="section-title__action"
			v-if="action"
		>

			<div
				v-if="actionType == 'custom'"
				class="section-title__action-item"
				@click="actionClicked"
			>{{ action }}
			</div>
			<router-link
				v-else-if="actionType == 'innerLink'"
				class="section-title__action-item"
				:to="actionLink"
			>{{ action }}
			</router-link>
			<a
				v-else
				class="section-title__action-item"
				:href="actionLink"
			>{{ action }}</a>

			<svg
				width="7"
				height="10"
				viewBox="0 0 7 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M1.49976 9.99927C1.24376 9.99927 0.987762 9.90127 0.792762 9.70627C0.401762 9.31527 0.401762 8.68327 0.792762 8.29227L4.09776 4.98727L0.917762 1.69427C0.534762 1.29627 0.545762 0.663272 0.942762 0.280272C1.34076 -0.102728 1.97376 -0.0917276 2.35676 0.304272L6.21876 4.30427C6.59776 4.69727 6.59276 5.32027 6.20676 5.70627L2.20676 9.70627C2.01176 9.90127 1.75576 9.99927 1.49976 9.99927Z"
					fill="#40AE49"
				/>
			</svg>

		</div>
	</h3>
</template>

<script>
export default {
	name: 'SectionTitle',

	props: {
		action: {type: String, default: null},
		actionType: {type: String, default: 'custom'},
		actionLink: {type: String, defaut: null}
	},

	methods: {
		actionClicked()
		{
			this.$emit('actionClicked');
		}
	}
}
</script>

<style lang="scss">

.section-title
{
	font-size: 24px;
	border-bottom: 1px solid $greyDelimiter;
	display: flex;
	justify-content: flex-start;
	padding-bottom: 12px;
	margin-bottom: 32px;

	&__action
	{
		display: flex;
		align-items: center;
		font-size: 16px;
		color: $green;
		cursor: pointer;
		padding-right: 6px;

		&-item
		{
			padding-right: 22px;
			margin-right: -8px;
			z-index: 2;
		}
	}

	@media (max-width: 1439px)
	{
		&__action
		{
			display: none;
		}
	}
}

</style>