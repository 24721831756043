<template>
	<label class="dont-forget-radio">
		<input
			type="radio"
			:checked="checked"
			@click="toggle"
			name="product"
			class="dont-forget-radio__input"
		/>
		<div class="dont-forget-radio__body">
			<span class="dont-forget-radio__box"></span>
			<span class="dont-forget-radio__text">Хлеб белый</span>
		</div>
	</label>
</template>

<script>
export default {
	name: 'DontForgetRadio',
	methods: {
		toggle()
		{
			this.checked = !this.checked
		}
	},
	props: {
		checked: {
			type: Boolean,
			default: false
		}
	},
}
</script>

<style
	lang="scss"
	scoped
>
.dont-forget-radio__input
{
	position: absolute;
	width: 0;
	height: 0;
	top: 0;
	left: 0;
	z-index: -10;
	visibility: hidden;
	opacity: 0;

	&:enabled
	{
		// включен
		& ~ .dont-forget-radio__body
		{
			display: flex;
			align-items: center;
			cursor: pointer;

			.dont-forget-radio__box
			{border-color: #c2c7cf; }

			.dont-forget-radio__text
			{
				font-family: $mainFontMediumNew;
				font-weight: 700;
				letter-spacing: 1px;
				font-size: 16px;
				line-height: 20px;
				color: #3D4248;
			}
		}

		// включен, но не выбран
		&:not(:checked)
		{
			& ~ .dont-forget-radio__body:hover .dont-forget-radio__box
			{
				border-color: rgba(25, 28, 33, 0.7);

				&:before
				{
					content: '';
					display: block;
					border-radius: 10px;
					width: 10px;
					height: 10px;
					background: #f0f1f3;
					position: relative;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}

		// включен и выбран
		&:checked ~ .dont-forget-radio__body .dont-forget-radio__box
		{
			border: 2px solid #40AE49;
			border-radius: 14px;
		}

		&:checked
		{
			& ~ .dont-forget-radio__body .dont-forget-radio__box:before
			{
				content: '';
				display: block;
				width: 18px;
				height: 18px;
				background: #40AE49;
				border-radius: 14px;
				position: relative;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			& ~ .dont-forget-radio__body .dont-forget-radio__text
			{ color: #191C21; }
		}

		// состояние фокуса
		&:hover ~ .dont-forget-radio__body .dont-forget-radio__text
		{color: #000000}
	}

	// выключен и выбран
	&:disabled:checked ~ .dont-forget-radio__body .dont-forget-radio__box
	{
		border-color: pink;
		background-color: gray;
	}
}

// базовые стили для выключенного состояния
.dont-forget-radio__box
{
	width: 28px;
	height: 28px;
	left: 0px;
	top: calc(50% - 28px / 2);
	border: 1px solid #C7CDD2;
	border-radius: 14px;
	display: inline-block;
	vertical-align: top;
}

.dont-forget-radio__text
{
	font-size: em(13);
	display: inline-block;
	vertical-align: top;
	margin-left: 10px;
}
</style>