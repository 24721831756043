<template>
	<div
		class="lunch-menu"
		:class="{ sticky }"
		v-dragscroll.x
	>
		<div
			class="lunch-menu__container"
			:class="{ sticky }"
		>
			<div
				class="lunch-menu__item"
				v-for="(mealType, index) in getMealTypes"
				:key="index"
				@click="$emit('select', mealType.code)"
				:class="{sticky, 'lunch-menu__item_selected': mealType.selected,}"
				:style="{backgroundColor: getBgColor(mealType.code)}"
			>
				<img
					class="lunch-menu__icon"
					:src="require(`@/assets/img/Personal/Menu/FoodTypes/${mealType.code}.svg`)"
				/>
				<div
					class="lunch-menu__icon lunch-menu__icon_done"
					v-if="constructorPage && mealType.selected"
				>
					<svg>
						<use xlink:href="#lunch-ok"></use>
					</svg>
				</div>
				<div class="lunch-menu__name">
					{{ mealType.code == "lunches" ? "Ланчи" : mealType.name }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {dragscroll} from 'vue-dragscroll';

export default {
	props: ['lunch', 'activeCategory', 'constructorPage', 'sticky'],
	/**
	 * дата
	 */
	data()
	{
		return {
			mealTypes: [
				{name: 'Супы', code: 'sup', active: false, selected: false},
				{name: 'Салаты', code: 'salat', active: false, selected: false},
				{name: 'Горячее', code: 'hot', active: false, selected: false},
				{name: 'Гарниры', code: 'garnir', active: false, selected: false},
				{name: 'Выпечка', code: 'vipechka', active: false, selected: false},
				{name: 'Десерты', code: 'desert', active: false, selected: false},
				{name: 'Напитки', code: 'juse', active: false, selected: false},
				{name: 'Вторые', code: 'second', active: false, selected: false},
				{name: 'Соусы', code: 'sauce', active: false, selected: false},
				{name: 'Коробка', code: 'box', active: false, selected: false},
				{name: 'Завтрак', code: 'breakfast', active: false, selected: false},
				{name: 'Роллы', code: 'roll', active: false, selected: false},
				{name: 'Суши и другое', code: 'sushi', active: false, selected: false},
				{name: 'Поке', code: 'poke', active: false, selected: false},
			]
		}
	},
	computed:
		{
			/**
			 * возвращает массив категорий ланча
			 */
			getMealTypes()
			{
				let mealTypes = [];
				let needMeals = Object.keys(this.lunch);
				for (let mealType of this.mealTypes)
				{
					if (needMeals.indexOf(mealType.code) == -1)
						continue;

					mealType.active = false;
					if (this.activeCategory == mealType.code)
						mealType.active = true;

					mealType.selected = this.lunch[mealType.code];
					mealTypes.push(mealType);
				}
				return mealTypes;
			},
		},
	methods:
		{
			getBgColor(category)
			{
				let color = "";
				let menuCategories = this.$store.state.menuCategories.filter(c => c.type == category);

				this.mealTypes.forEach(item =>
				{
					menuCategories.forEach(r =>
					{
						if (item.code == r.type)
							color = r?.color;
					});
				});
				return `#${color}`;
			},
			/**
			 * отправляет эмит с выбранной категорией
			 */
			selectCategory(mealType)
			{
				this.$emit('select', mealType.code);
			}
		},
	directives:
		{
			dragscroll
		}
}

</script>

<style lang="scss">
.lunch-menu
{
	display: flex;
	flex-shrink: 0;
	overflow-y: overlay;
	padding: 20px 0;

	&__container
	{
		display: flex;
		flex-shrink: 0;
	}

	&__item
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		cursor: pointer;
		padding-top: 16px;
		width: 88px;
		height: 88px;
		min-width: 88px;
		background: #fff;
		border: 1px solid $greyBorder;
		border-radius: 12px;
		box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08);
		margin-right: 12px;
	}

	&__item_selected
	{
		.lunch-menu__icon
		{
			display: none;

			&_done
			{display: block; }
		}
	}

	&__item:hover
	{
		color: $green;
		transition: .2s ease color;

		.lunch-menu__icon
		{transform: translateY(-4px); }
	}

	&__count
	{
		position: absolute;
		right: 8px;
		top: 8px;
		font-size: 9px;
		line-height: 14px;
		color: $greyLightText;
		background: $greyBackground;
		padding: 0 3px;
		border: 1px solid $greyBorder;
		border-radius: 14px;
	}

	&__icon, &__icon svg
	{
		width: 40px;
		height: 40px;
		transition: .2s ease transform;
	}

	&__name
	{
		font-size: 13px;
		font-family: $mainFontBoldNew;
	}

}

.sticky.lunch-menu
{
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	flex-shrink: 0;
	padding: 0 32px;
	overflow-y: overlay;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 72px;
	background: #fff;
	border-bottom: 1px $greyBorder solid;
	margin: 0;

	&__container
	{
		display: flex;
		width: 100%;
		max-width: 1320px;
	}

	&__item
	{
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		cursor: pointer;
		width: auto;
		height: 40px;
		min-width: 88px;
		background: $greyBorder;
		border: none;
		padding: 4px 12px;
		min-width: unset;
		border-radius: 40px;
		box-shadow: none;
		margin-right: 12px;

		.lunch-menu__count
		{display: none; }

		.lunch-menu__icon
		{
			width: 32px;
			height: 32px;
			margin-right: 10px;

			svg
			{width: 32px; height: 32px;}

			&_selected
			{
				background: $green;
				color: #ffff;

				svg
				{fill: white; }

				&:after
				{border-left-color: $green; }
			}
		}
	}

	&__icon
	{width: 40px; height: 40px; }

	&__name
	{font-size: 13px; font-family: $mainFontBoldNew; }

}
</style>
