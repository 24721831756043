<template>
	<div
		class="product-wr"
		v-click-outside="closePopup"
	>
		<div class="product">
			<div class="product-left">
				<div class="product__special">
					<svg
						fill="transparent"
						width="67"
						height="28"
						v-if="item.saleleader"
					>
						<use xlink:href="#sale-icon"></use>
					</svg>
					<svg
						fill="transparent"
						width="67"
						height="28"
						v-if="item.hit"
					>
						<use xlink:href="#hit-icon"></use>
					</svg>
				</div>
				<div class="product-socials">
					<div class="product-socials__hidden">
						<transition
							name="socials-anim"
							@enter="enter"
							@leave="leave"
							@afterEnter="afterEnter"
							@afterLeave="afterLeave"
						>
							<div
								class="socials-anim"
								:key=item.id
								v-if="socials"
							>
								<div
									@click="facebook(urlPath, item.name, item.preview_picture.big, item.preview_text)"
									class="product-social product-social_fb"
								>
									<svg
										fill="transparent"
										width="35"
										height="35"
									>
										<use xlink:href="#fb-icon_colorize"></use>
									</svg>
								</div>
								<div
									@click="telegram(urlPath, item.name)"
									class="product-social product-social_telegram"
								>
									<svg
										fill="transparent"
										width="35"
										height="35"
									>
										<use xlink:href="#telegram-icon_colorize"></use>
									</svg>
								</div>
								<div
									@click="odnoklassniki(urlPath, item.name)"
									class="product-social product-social_ok"
								>
									<svg
										fill="transparent"
										width="35"
										height="35"
									>
										<use xlink:href="#ok-icon_colorize"></use>
									</svg>
								</div>
								<div
									@click="instagram(urlPath)"
									class="product-social product-social_inst"
								>
									<svg
										fill="transparent"
										width="35"
										height="35"
									>
										<use xlink:href="#inst-icon_colorize"></use>
									</svg>
								</div>
								<div
									@click="vkontakte(urlPath, item.name, item.preview_picture.big)"
									class="product-social product-social_vk"
								>
									<svg
										fill="transparent"
										width="35"
										height="35"
									>
										<use xlink:href="#vk-icon_colorize"></use>
									</svg>
								</div>
							</div>
						</transition>
					</div>
					<div
						class="product-social__share"
						@click="socials = !socials"
					>
						<span class="product-social__btn">
							<svg
								width="35"
								height="35"
								viewBox="0 0 35 35"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									opacity="0.1"
									width="35"
									height="35"
									rx="17.5"
									fill="#40AE49"
								/>
								<path
									d="M21.3189 18.376H18.5349V21.096H16.4709V18.376H13.7029V16.424H16.4709V13.704H18.5349V16.424H21.3189V18.376Z"
									fill="#40AE49"
								/>
							</svg>
						</span>
						<span class="product-social__text">Поделиться</span>
					</div>
				</div>
				<div class="product-img">
					<swiper
						:slides-per-view="1"
						:space-between="50"
						:options="swiperOption"
						ref="mySwiper"
						@slideChange="onSlideChange()"
					>
						<swiper-slide><img
							loading="lazy"
							:src="item.preview_picture.big"
							alt=""
						></swiper-slide>
						<swiper-slide v-if="!!item.detail_picture.big"><img
							loading="lazy"
							:src="item.detail_picture.big"
							alt=""
						></swiper-slide>
						<swiper-slide v-else-if="!!item.detail_picture"><img
							loading="lazy"
							:src="item.detail_picture"
							alt=""
						></swiper-slide>
						<div
							class="swiper-button-prev"
							slot="button-prev"
						></div>
						<div
							class="swiper-button-next"
							slot="button-next"
						></div>
					</swiper>
					<div class="product-img__pagination">
						<div
							class="product-img__pagination_btn"
							:class="{[$style.active_img]:currentFirstImg}"
							@click="changeCurrentImg(true)"
						>
							<img
								:src="item.preview_picture.thumb"
								loading="lazy"
								alt=""
							>
						</div>
						<div
							class="product-img__pagination_btn"
							:class="{[$style.active_img]:!currentFirstImg}"
							@click="changeCurrentImg(false)"
							v-if="!!item.detail_picture.thumb"
						>
							<img
								:src="item.detail_picture.thumb"
								loading="lazy"
								alt=""
							>
						</div>
						<div
							class="product-img__pagination_btn"
							:class="{[$style.active_img]:!currentFirstImg}"
							@click="changeCurrentImg(false)"
							v-else-if="!!item.detail_picture"
						>
							<img
								:src="item.detail_picture"
								loading="lazy"
								alt=""
							>
						</div>
					</div>
				</div>
			</div>
			<div class="product-right">
				<div
					class="product__title"
					v-html="item.name"
				></div>
				<div class="product-consist">
					<div class="product-consist__title">Состав продукта:</div>
					<div class="product-consist-items">
						<div
							ref="infoIcon"
							class="product-consist-items__text"
						>
							<div
								class="product-consist__item"
								v-for="(sostavItem, index) of this.consist"
							>{{ sostavItem }}
							</div>
						</div>
					</div>
					<div
						class="product-info"
						v-if="this.infoIconVar"
					>
						<div class="product__info-icon">
							<svg
								width="25"
								height="25"
								viewBox="0 0 25 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g opacity="0.7">
									<circle
										cx="12.5"
										cy="12.5"
										r="12"
										stroke="#747678"
									/>
									<path
										d="M11.4025 9.468H13.5865V17H11.4025V9.468ZM12.4945 8.418C12.0932 8.418 11.7665 8.30133 11.5145 8.068C11.2625 7.83467 11.1365 7.54533 11.1365 7.2C11.1365 6.85467 11.2625 6.56533 11.5145 6.332C11.7665 6.09867 12.0932 5.982 12.4945 5.982C12.8959 5.982 13.2225 6.094 13.4745 6.318C13.7265 6.542 13.8525 6.822 13.8525 7.158C13.8525 7.522 13.7265 7.82533 13.4745 8.068C13.2225 8.30133 12.8959 8.418 12.4945 8.418Z"
										fill="#747678"
									/>
								</g>
							</svg>
						</div>
						<Tooltip
							class="product-tooltip right-aligned"
							:info="false"
							:nutrition="false"
							:list="this.consist"
						/>
					</div>
				</div>
				<ProductNutrition :item="item"/>
				<div class="product-descr">
					<div class="product-descr__left">
						<div
							class="product-descr__descr"
							@click="descrShow = !descrShow"
						>
							<div class="product-descr__descr-text">
								Полное описание
							</div>
							<transition name="popup-fade">
								<div
									@click.stop
									class="product-full-descr"
									v-if="this.descrShow"
								>
									<div class="product-full-descr__title">Описание</div>
									<div class="product-full-descr__content">
										<p
											v-if="item.detail_text.trim() !== ''"
											v-html="item.detail_text"
										></p>
										<p
											v-else
											v-html="item.sostav"
										></p>
									</div>
									<div
										class="product-full-descr__close"
										@click="descrShow = false"
									>
										<svg
											width="10"
											height="10"
											fill="none"
										>
											<use xlink:href="#remove-item-icon"></use>
										</svg>
									</div>
								</div>
							</transition>
						</div>
					</div>
					<div class="product-descr__right">
						<div class="product-descr-weight">
							<div class="product-descr-weigth__label">Вес:</div>
							<div class="product-descr-weight__value">{{ item.weight }} гр.</div>
						</div>
					</div>
				</div>
				<div class="product-seller">
					<span class="product-seller__label">Продавец:</span>
					<div
						class="product-seller__title-rest"
						v-html="returnCurrentRestarauntName"
					></div>
					<div class="product-rest__info">
						<div class="product-rest__info-icon">
							<svg
								width="25"
								height="25"
								viewBox="0 0 25 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g opacity="0.7">
									<circle
										cx="12.5"
										cy="12.5"
										r="12"
										stroke="#747678"
									/>
									<path
										d="M11.4025 9.468H13.5865V17H11.4025V9.468ZM12.4945 8.418C12.0932 8.418 11.7665 8.30133 11.5145 8.068C11.2625 7.83467 11.1365 7.54533 11.1365 7.2C11.1365 6.85467 11.2625 6.56533 11.5145 6.332C11.7665 6.09867 12.0932 5.982 12.4945 5.982C12.8959 5.982 13.2225 6.094 13.4745 6.318C13.7265 6.542 13.8525 6.822 13.8525 7.158C13.8525 7.522 13.7265 7.82533 13.4745 8.068C13.2225 8.30133 12.8959 8.418 12.4945 8.418Z"
										fill="#747678"
									/>
								</g>
							</svg>
						</div>
						<Tooltip
							class="product-rest-tooltip"
							:nutrition="false"
							:info="restTooltipInfo"
						/>
					</div>
				</div>
				<div
					class="product-buy"
					v-if="!isConstr"
				>
					<div class="product-buy__counter">
						<Counter
							:checkCounterBetweenChange="checkCartBeforeAddProduct"
							:value="count"
							@change="changeCartItem"
						/>
					</div>
					<div class="product-buy-cost">
						<div
							class="product-buy-cost__old"
							v-if="item.price_old"
						>{{ item.price_old }} ₽
						</div>
						<div class="product-buy-cost__now"><span class="product-buy-cost__now-value">{{
								item.price
							}}</span></div>
					</div>
				</div>
				<div
					class="product-marks"
					v-if="!isConstr && +item.balls > 0"
				>
					<div class="product-marks__label">Начислится:</div>
					<div class="product-marks__value">+{{ item.balls }} балл{{ returnWord(item.balls) }}</div>
				</div>
				<UiButton
					size="medium"
					class="product__btn"
					@click="changeCartItem(1); closePopup()"
					v-if="!isConstr"
					:loading="loading"
				>
					В корзину
				</UiButton>
				<button
					class="button button_red product__btn product__btn_lunch"
					v-if="isConstr"
					@click="closePopup(); chose()"
				>
					Добавить
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Counter from '@/components/ui/Counter.vue';
import Tooltip from '@/components/block/Tooltip.vue'
import WordEnding from '@/mixins/returnWordEnding.js'
import ProductNutrition from "../block/ProductNutrition";
import 'swiper/css/swiper.css'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {useChangeCartItem, useYandexMetrika} from "@/composables";
import {UiButton} from "ui-kit";

export default {
	setup()
	{
		const {ecommerceAddToCart, ecommerceRemoveFromCart} = useYandexMetrika();
		const {checkCartBeforeAddProduct, changeCartItem, initProduct, count, loading} = useChangeCartItem();

		return {
			ecommerceAddToCart,
			ecommerceRemoveFromCart,
			checkCartBeforeAddProduct,
			changeCartItem,
			initProduct,
			count,
			loading
		};
	},
	components: {UiButton, ProductNutrition, Counter, Tooltip, Swiper, SwiperSlide},
	props: ['item', 'isConstr'],
	mixins: [WordEnding],
	data()
	{
		return {
			descrShow: false,
			infoIconVar: false,
			consist: '',
			restTooltipInfo: {},
			socials: false,
			url: '',
			urlPath: '',
			swiperOption: {
				preloadImages: false,
				lazy: true,
				loop: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			},
			currentFirstImg: true,
		}
	},
	methods:
		{
			onSlideChange()
			{
				if (this.$refs.mySwiper.$swiper.activeIndex == 0)
					this.currentFirstImg = true;
				else this.currentFirstImg = false;
			},
			changeCurrentImg(val)
			{
				let index = val ? 0 : 1;
				this.currentFirstImg = val;
				this.$refs.mySwiper.$swiper.slideTo(index, 500);
			},
			/**
			 * vk share link
			 */
			vkontakte(purl, ptitle, pimg)
			{
				this.url = 'http://vk.com/share.php?';
				this.url += 'url=' + encodeURIComponent(purl);
				this.url += '&title=' + encodeURIComponent(ptitle);
				this.url += '&image=' + encodeURIComponent(pimg);
				this.url += '&noparse=true';
				this.popup(this.url);
			},
			/**
			 * odnoklassniki share link
			 */
			odnoklassniki(purl, text)
			{
				this.url = 'http://connect.ok.ru/dk?st.cmd=addShare&st.s=1';
				this.url += '&st._surl=' + encodeURIComponent(purl);
				this.url += '&st.comments=' + encodeURIComponent(text);
				this.popup(this.url);
			},
			/**
			 * facebook share link
			 */
			facebook(purl, ptitle, pimg, text)
			{
				this.url = 'http://www.facebook.com/sharer.php?s=100';
				this.url += '&p[title]=' + encodeURIComponent(ptitle);
				this.url += '&p[summary]=' + encodeURIComponent(text);
				this.url += '&p[url]=' + encodeURIComponent(purl);
				this.url += '&p[images][0]=' + encodeURIComponent(pimg);
				this.popup(this.url);
			},
			/**
			 * telegram share link
			 */
			telegram(purl, ptitle)
			{
				this.url = 'https://telegram.me/share/url?';
				this.url += 'url=' + encodeURIComponent(purl);
				this.url += '&text=' + encodeURIComponent(ptitle);
				this.popup(this.url);
			},
			/**
			 * instagram share link
			 */
			instagram(purl)
			{
				this.url = 'https://www.instagram.com/?';
				this.url += 'url=' + encodeURIComponent(purl);
				this.popup(this.url);
			},
			/**
			 * открывает share link окно
			 */
			popup(url)
			{
				window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
			},
			/**
			 * закрывает попап
			 */
			closePopup()
			{
				this.$emit('closePopup');
			},
			/**
			 * подсказка с контекстом выполнения
			 */
			resize()
			{
				this.$refs.infoIcon.clientHeight > 120 ? this.infoIconVar = true : this.infoIconVar = false;
			},
			/**
			 * добавляет meal в ланч с попапа
			 */
			chose()
			{
				this.$emit('chosefood');
			},
			/**
			 ** transitions hooks
			 **/
			enter(element)
			{
				const height = getComputedStyle(element).height;

				element.style.height = '0px';

				setTimeout(() =>
				{
					element.style.height = height;
				});
			},

			/**
			 ** transitions hooks
			 **/
			leave(element)
			{
				element.style.height = getComputedStyle(element).height;

				setTimeout(() =>
				{
					element.style.height = '0px';
				});
			},

			/**
			 ** transitions hooks
			 **/
			afterEnter(element)
			{
				element.style.height = 'auto';
			},

			/**
			 ** transitions hooks
			 **/
			afterLeave(element)
			{
				element.style.height = 'auto';
			},

		},
	/**
	 * отображение подсказки
	 */
	mounted()
	{
		this.urlPath = window.location.href;

		this.$refs.infoIcon.clientHeight > 120 ? this.infoIconVar = true : this.infoIconVar = false;

		window.addEventListener('resize', this.resize, {passive: true});
		/**
		 *	формирование массива состава продукта
		 */
		this.consist = this.item.sostav.split(',').filter(item => item.length > 0);

		this.initProduct(this.item);
	},
	// отображение подсказки
	updated()
	{
		this.resize();
	},
	// передача данных в подсказку
	created()
	{
		this.restTooltipInfo.name = this.returnActiveRestaurantName;
		this.restTooltipInfo.ogrn = '2564578946554';
		this.restTooltipInfo.inn = '2564578946554';
	},
	computed:
		{
			/**
			 * возвращает название ресторана
			 */
			returnCurrentRestarauntName()
			{
				for (let rest of this.$store.state.restaraunts)
				{
					if (rest.id == this.$store.state.address.restarauntId)
						return rest.name
				}
				return '“Название ресторана”'
			},
		},
	// удаляет листенер
	beforeDestroy()
	{
		window.removeEventListener('resize', this.resize);
	}
}
</script>

<style
	lang="scss"
	module
>
.active_img
{
	border: 2px solid $green !important;
	border-radius: 10px;
}
</style>

<style lang="scss">
.socials-anim
{
	transition: all .2s;
	overflow: hidden;
}

.socials-anim-enter-to
{
	height: auto;
}

.socials-anim-leave-active, .socials-anim-enter-active
{
	@include ease(.22s);
}

.socials-anim-enter, .socials-anim-leave-to
{
	opacity: 0;
}

.product
{
	width: 100%;
	display: flex;
	background-color: #fff;
	border-radius: 10px;
	letter-spacing: .1px;
	padding: 40px;
}

.product-info
{
	position: absolute;
	bottom: 15px;
	right: 9px;
	width: fit-content;

	.product__info-icon
	{
		&:hover + .product-tooltip
		{
			opacity: 1;
		}
	}
}

.product__info-icon
{
	line-height: 0;
	user-select: none;
	cursor: pointer;
	@include ease(.125s);

	&:hover
	{
		svg
		{
			path
			{
				fill: #fff;
			}

			g
			{
				fill: $textColor;
				opacity: 1;
			}

			circle
			{
				stroke: $textColor;
			}
		}
	}
}

.product-tooltip
{
	cursor: default;
	width: 216px;
	@include ease(.125s);
	bottom: 40px;
	left: -160px;
	opacity: 0;
}

.product-left
{
	position: relative;
	margin-right: 65px;
}

.product__special
{
	position: absolute;
	top: 0px;
	left: 0px;
	line-height: 0;
	z-index: 2;

	svg
	{
		margin-right: 5px;
	}
}

.product-socials
{
	position: absolute;
	bottom: 0;
	z-index: 10;
}

.product-social
{
	line-height: 0;
	@include ease(.125s);
	margin-bottom: 4px;

	svg
	{
		cursor: pointer;

		&:hover
		{
			opacity: .7;
		}
	}
}

.product-social__share
{
	line-height: 0;
	@include ease(.125s);
	align-items: center;
	color: $green;
	font-family: $mainFontBold;
	display: flex;
	font-size: 10px;
	line-height: 100%;
	text-transform: uppercase;
	user-select: none;
	cursor: pointer;
}

.product-social__btn
{
	margin-right: 8px;
	margin-top: 1px;
	margin-left: -1px;
	@include ease(.125s);

	&:hover
	{
		svg
		{
			border-radius: 50%;
			opacity: 1;

			rect
			{
				fill: $green;
				stroke: $green;
				opacity: 1;
			}

			path
			{
				fill: #fff;
			}
		}
	}
}

.product-social__text
{
	font-size: 10px;
	padding-top: 1px;
	display: inline-block;
	letter-spacing: 0;
}

.product-img
{
	width: 580px;
	height: 560px;
	margin-top: 27px;
	margin-left: 33px;

	img
	{
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	&__pagination
	{
		display: flex;
		place-content: center;
		@media (max-width: 460px)
		{
			display: none;
		}

		&_btn
		{
			width: 80px;
			height: 80px;
			border: 2px solid $greyBorder;
			border-radius: 10px;
			margin-right: 10px;
			cursor: pointer;
			display: grid;
			place-content: center;

			img
			{
				display: block;
				padding: 2px;
			}
		}
	}
}

.product-right
{
	font-family: $mainFontBold;
	max-width: 285px;
}

.product__title
{
	font-family: $mainFontBold;
	font-size: 28px;
	line-height: 100%;
	color: $textColor;
	margin-bottom: 26px;
}

.product-consist
{
	margin-bottom: 14px;
	position: relative;
}

.product-consist__title
{
	font-size: 16px;
	line-height: 100%;
	color: $textColor;
	margin-bottom: 15px;
}

.product-consist-items
{
	margin-right: -6px;
	position: relative;
	max-height: 120px;
	overflow: hidden;

	.product__info-icon
	{
		position: absolute;
		bottom: 14px;
		right: 14px;
	}
}

.product-consist__item
{
	font-family: $mainFont;
	font-size: 13px;
	line-height: 100%;
	color: #666;
	background-color: rgba(116, 118, 120, 0.1);
	padding: 7px 25px 0;
	height: 30px;
	border-radius: 60px;
	display: inline-block;
	margin-right: 6px;
	margin-bottom: 10px;
}

.product-descr
{
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.product-descr__descr
{
	position: relative;
	z-index: 2;
}

.product-descr__descr-text
{
	font-family: $mainFontMedium;
	color: $green;
	cursor: pointer;
	text-decoration: underline;
	font-size: 14px;
	line-height: 100%;
}

.product-descr-weight
{
	display: flex;
}

.product-descr-weigth__label
{
	font-family: $mainFont;
	font-size: 14px;
	line-height: 100%;
	color: $greyText;
	padding-right: 1px;
}

.product-descr-weight__value
{
	font-family: $mainFontMedium;
	font-size: 14px;
	line-height: 100%;
	color: $textColor;
	width: 54px;
	text-align: right;
	padding-right: 2px;
}

.product-seller
{
	display: flex;
	align-items: center;
	margin-bottom: 22px;
}

.product-seller__label
{
	font-family: $mainFont;
	font-size: 14px;
	line-height: 100%;
	color: $greyText;
	padding-right: 8px;
}

.product-seller__title-rest
{
	font-family: $mainFontMedium;
	font-size: 14px;
	line-height: 100%;
	color: $green;
	margin-right: 5px;
	white-space: nowrap;
	overflow: hidden;
	max-width: 167px;
	text-overflow: ellipsis;
}

.product-buy
{
	display: flex;
	margin-bottom: 12px;
}

.product-buy__counter
{
	max-width: 124px;
	width: 100%;
}

.product-buy-cost
{
	display: flex;
	align-items: center;
	padding-right: 6px;
}

.product-buy-cost__old
{
	font-family: $mainFontMedium;
	font-size: 14px;
	line-height: 17px;
	text-decoration-line: line-through;
	color: $textColor;
	opacity: 0.6;
	padding-right: 1px;
	width: 54px;
	text-align: right;
}

.product-buy-cost__now
{
	font-family: $mainFontBold;
	font-size: 36px;
	line-height: 40px;
	color: $textColor;
	display: flex;

	&:after
	{
		content: '₽';
		font-size: 20px;
		line-height: 1.5;
	}

	.product-buy-cost__now-value
	{
		padding-right: 5px;
		width: 86px;
		text-align: right;
	}
}

.product-marks
{
	display: flex;
	padding-left: 47px;
	margin-bottom: 23px;
}

.product-marks__label
{
	font-family: $mainFont;
	font-size: 14px;
	line-height: 17px;
	color: $greyText;
	margin-right: 16px;
}

.product-marks__value
{
	font-family: $mainFontMedium;
	font-size: 14px;
	line-height: 17px;
	color: $green;
}

.product__btn
{
	cursor: pointer;
	user-select: none;
	font-family: $mainFontBold;
	font-size: 16px;
	width: 283px;
	height: 50px;
	margin: 0 auto;
}

.product-full-descr
{
	position: absolute;
	width: 280px;
	background-color: #fff;
	box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.05);
	right: 150px;
	bottom: 0px;
	padding: 26px;
	margin-bottom: 20px;
}

.product-full-descr__title
{
	font-family: $mainFontBold;
	font-size: 16px;
	line-height: 100%;
	color: $textColor;
	margin-bottom: 20px;
}

.product-full-descr__content
{
	p
	{
		font-family: $mainFont;
		font-size: 13px;
		line-height: 21px;
		color: #666;

		&:not(:last-child)
		{
			margin-bottom: 23px;
		}
	}
}

.product-full-descr__close
{
	cursor: pointer;
	position: absolute;
	line-height: 0;
	top: 15px;
	right: 15px;
}

.product-rest__info
{
	position: relative;
}

.product-rest__info-icon
{
	line-height: 0;
	user-select: none;
	cursor: pointer;
	@include ease(.125s);

	&:hover
	{
		svg
		{
			path
			{
				fill: #fff;
			}

			g
			{
				fill: $textColor;
				opacity: 1;
			}

			circle
			{
				stroke: $textColor;
			}
		}
	}

	&:hover + .product-rest-tooltip
	{
		z-index: 1;
		opacity: 1;
		visibility: visible;
	}
}

.product-rest-tooltip
{
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.05);
	bottom: -120px;
	left: -94px;
	@include ease(.125s);
}

@media (max-width: 1200px)
{
	.product-social__text
	{
		padding-top: 5px;
	}
	.product-wr
	{
		max-width: 100vw;
	}
	.product
	{
		flex-direction: column;
		padding: 15px 20px 30px;
	}
	.product-left
	{
		width: fit-content;
		margin: 0 auto 14px;
	}
	.product-img
	{
		margin: 0;
		width: 90vw;
		max-width: 400px;
		height: auto;
		min-height: 227px;
	}
	.product__special
	{
		left: -19px;
		z-index: 2;
	}
	.product-socials
	{
		bottom: -4px;
		left: -5px;
	}
	.product-social
	{
		width: fit-content;

		svg
		{
			width: 25px;
			height: 25px;
		}
	}
	.product-social__btn
	{
		padding-top: 4px;

		svg
		{
			width: 25px;
			height: 25px;

		}
	}
	.product-right
	{
		max-width: unset;
		margin: 0 auto;
	}
	.product__title
	{
		font-size: 22px;
		margin-bottom: 14px;
	}
	.product-consist__title
	{
		font-size: 15px;
	}
	.product-consist
	{
		margin-bottom: 4px;
	}
	.product-right
	{
		padding-left: 1px;
	}
	.product-marks
	{
		margin-bottom: 19px;
	}
	.product-tooltip
	{
		left: unset;
		bottom: -4px;
		right: 47px;
	}
	.product-full-descr
	{
		left: 10%;
	}
	.product-rest-tooltip
	{
		left: unset;
		right: -30px;
		bottom: -120px;
	}
}

.swiper-container
{
	.swiper-button-next, .swiper-button-prev
	{
		background: #FFFFFF;
		border: 2px solid #F0F0F0;
		box-sizing: border-box;
		border-radius: 40px;
		height: 48px;
		width: 48px;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);

		&:after
		{
			font-size: 16px;
			font-weight: bold;
			color: $green;
			margin-left: 3px;
		}
	}

	.swiper-button-prev:after
	{margin-right: 5px;}
}
</style>